<template>
  <div>
     <template v-if="pageConfg">
        <component :is="getComponentList()" :pageConfg="pageConfg" :key="keyPage"></component>
      </template>
      <div v-else class="d-flex justify-center align-center" style="height: 100vh">
        <!--default-app-bar > </default-app-bar-->
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>
  </div>

</template>

<script>
  import { NgForm, handler } from '@/components/gui/NgForm'
  import createComponentPageList from '@/modules/DynamicPageList'

  export default {
    name: 'default-list',
    data () {
      return {
        form: null,
        keyForm: 1,
        keyPage: 1,
        loading: true,
        showFilters: false,
        pageConfg: null
      }
    },
    mounted(){
        // console.log('fullpath', this.$route.params)
        const page = this.$store.getters.getPageConfig(this.$route.fullPath);
        if(page){
            this.pageConfg = page;
            // this.cleanForm();
            /* this.querySelections().then(data => {
                      this.items = data.items
                      this.total = data.total
            });*/
        }else{
          this.getGuiPage(this.$route.fullPath).then((config) => {
            //console.log(config)
                const p = JSON.parse(JSON.stringify(config));
                let act = 'list';
                if (this.$route.params.extra) {
                  act = 'list-' + this.$route.params.extra;
                }
                this.pageConfg = p[act];
                for(const a in p){
                    if(p[a] ){
                        this.$store.commit('addPage', p[a] );
                    }
                }
                this.keyPage++;
            
            });
        }
    },
    props: ['info'],
    methods: {
      getGuiPage (url) {
        return new Promise((resolve, reject) => {
            this.$http.post('api/v1/gui'+ url, { url }  ).then(data => {
                 resolve( data.config  );
            }).catch(err => {
                
            }).finally(() => (  this.loading = false ));
        });
      },
      getComponentList() {
        return createComponentPageList({}, this.pageConfg.tmpl);
      },
     /* getActionIcon(name){
            //console.log(this.pageConfg['actions'], '....', name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                return this.pageConfg['actions_btn'][name]['icon'];
            }
            return "";
      },
      getActionName(name){
            //console.log(this.pageConfg['actions'], '....', name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                return this.pageConfg['actions_btn'][name]['label'];
            }
            return "";
      },
      executeModuleAction(name){
        if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){

          this.executeActionList({ action: this.pageConfg['actions_btn'][name], itemAction: null })
        }
          
      },
      executeActionList($event){
        let hook = 'hook_'+$event.action.name;
        if($event.action.hook){
            hook = $event.action.hook;
        }
        window['managerHooks'].executeModuleAction(hook, $event.action, $event.itemAction, this);
        
      },*/
    }
  }
</script>
